.delivery-hero-wrapper {
  display: flex;
  flex-direction: column-reverse;
  border-top: solid 1px black;
  border-bottom: 2px black solid;
  align-items: center;
  justify-content: center;

  background-color: var(--primary100);
}

.delivery-hero-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.delivery-hero-text-wrapper > * {
  width: 90%;
}

.delivery-hero-title {
  font-size: 2.4rem;
  text-transform: uppercase;
  margin: 20px 0 10px 0;
  color: var(--secondary100);
}

.delivery-hero-text {
  font-family: "heebo";
  font-weight: 500;
  margin: 10px 0 20px 0;
  color: var(--secondary100);
}

.delivery-hero-img {
  width: 100%;
  height: fit-content;
}

@media (min-width: 917px) {
  .delivery-hero-wrapper {
    flex-direction: row;
  }
  .delivery-hero-img {
    width: 50%;
    height: fit-content;
  }
}

@media (min-width: 917px) and (max-width: 1389px) {
  .delivery-hero-text-wrapper > * {
    width: 85%;
  }

  .delivery-hero-title {
    font-size: 4.1vw;
  }
}

@media (min-width: 1389px) and (max-width: 2299px) {
  .delivery-hero-text-wrapper > * {
    width: 75%;
  }

  .delivery-hero-title {
    font-size: 4.31rem;
    line-height: 80px;
  }

  .delivery-hero-text {
    font-size: 1.2rem;
  }
}

@media (min-width: 2300px) {
  .delivery-hero-text-wrapper > * {
    width: 90%;
  }

  .delivery-hero-title {
    font-size: 7rem;
    line-height: normal;
  }

  .delivery-hero-text {
    font-size: 1.5rem;
  }
}
