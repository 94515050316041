.delivery-info-wrapper {
  padding: 30px 50px;
  margin-top: 40px;
}

.delivery-info-btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.coupon-height {
  height: 120px;
}

.coupon-wrapper,
.coupon-btn {
  background: var(--secondary100);
  color: var(--tertiary100);
}

.coupon-wrapper {
  background: var(--secondary100);
  color: var(--tertiary100);

  position: fixed;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--secondary100);

  height: 120px;

  font-size: 5vw;
}

.coupon-wrapper-text {
  margin-left: 20px;
  width: 90%;
  text-transform: uppercase;
}

.coupon-btn {
  position: relative !important;
  margin-right: 15px;

  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

@media (min-width: 560px) and (max-width: 919px) {
  .coupon-wrapper {
    font-size: 25px;
    height: 100px;
  }

  .coupon-height {
    height: 100px;
  }

  .delivery-info-wrapper {
    margin-top: 50px;
  }

  .coupon-wrapper-text {
    justify-content: flex-start;
    margin-left: 5%;
  }
}

@media (min-width: 920px) {
  .coupon-wrapper {
    font-size: 40px;
    height: 100px;
  }

  .coupon-height {
    height: 100px;
  }

  .delivery-info-wrapper {
    margin-top: 50px;
  }

  .coupon-wrapper-text {
    justify-content: flex-start;
    margin-left: 5%;
  }
}
