.footer-wrapper {
  background: var(--secondary100);
  color: var(--tertiary100);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 30px;
}

.footer-title {
  font-size: 40px;
}

.footer-subtext {
  font-size: 23px;
}

.footer-text-wrapper {
  display: flex;
  flex-direction: row !important;
  justify-content: flex-end;
  font-family: "Inter";
  margin-top: 10px;
}

.powered-style {
  width: 50%;
}

@media (min-width: 560px) {
  .powered-style {
    width: 200px;
  }

  .footer-text-wrapper {
    margin-top: 0px;
  }
}
