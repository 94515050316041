.delivery-btn-wrapper {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: black;
  width: 100%;
  margin: 0px 0px 30px 0px;
}

.delivery-btn-img {
  border-radius: 30px;
  width: inherit;
}

.delivery-btn-info-wrapper {
  display: flex;
  flex-direction: row;
  width: inherit;
  margin-top: 15px;
}

.delivery-btn-logo {
  width: 50px;
  margin-right: 15px;
}

.delivery-main-wrapper {
  font-family: "Heebo";
  font-size: 14px;
}

.delivery-main-title {
  font-weight: 700;
}

@media (min-width: 560px) and (max-width: 899px) {
  .delivery-btn-wrapper {
    width: 200px;
    margin: 0px 10px 30px 10px;
  }
}

@media (min-width: 900px) and (max-width: 1439px) {
  .delivery-btn-wrapper {
    width: 300px;
    margin: 0px 10px 30px 10px;
  }
}

@media (min-width: 1440px) and (max-width: 1570px) {
  .delivery-btn-wrapper {
    width: 400px;
    margin: 0px 20px 30px 20px;
  }
}

@media (min-width: 1570px) {
  .delivery-btn-wrapper {
    width: 450px;
    margin: 0px 20px 30px 20px;
  }
}
